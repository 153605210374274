<!-- 供应商管理 -->
<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20" ref="dataForm" :model="searchForm">
        <FormItem prop="supplier_name">
          <span class="label">供应商名称：</span>
          <Select class="iviewIptWidth250 marginRight50" clearable filterable v-model="searchForm.supplier_id">
            <Option v-for="item in supplierSelect" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">评价时间：</span>
          <DatePicker format="yyyy-MM-dd" type="date" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker format="yyyy-MM-dd" class="marginRight50" type="date" placeholder="请选择" v-model="searchForm.evaluation_end_time" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
        </FormItem>
        <FormItem>
          <span class="label">审批时间：</span>
          <DatePicker format="yyyy-MM-dd" type="date" class="iviewIptWidth200" placeholder="请选择" @on-change="changeTime2($event, 1)" clearable></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker format="yyyy-MM-dd" type="date" class="iviewIptWidth200" placeholder="请选择" v-model="searchForm.approval_end_time" :options="options" @on-change="changeTime2($event, 2)" clearable></DatePicker>
        </FormItem>
        <FormItem prop="status">
          <span class="label">状态：</span>
          <Select class="iviewIptWidth250" clearable v-model="searchForm.status">
            <Option v-for="item in statusSelect" :value="item.status_str" :key="item.status_str">{{ item.status_str }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="newAdded">新增</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :pages="pages" @on-sort-change="changeSort" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :loading="isLoad" :total="total"></Table>
    </div>
    <!-- 驳回原因 -->
    <Modal v-model="reasonSTATUS" title="驳回原因" label-position="left" width="400" :footer-hide="true" :closable="false" class="modal">
      <div class="body">
        <Input v-model="dismiss_reason" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入"></Input>
        <div class="foot">
          <span class="pageBtn finger btnSure" v-if="!btnStatus" @click="commitreason">确定</span>
          <span class="pageBtn finger marginLeft20 btnCancle" v-if="!btnStatus" @click="reasonSTATUS = false">取消</span>
          <span class="pageBtn finger btnSure" v-if="btnStatus" @click="reasonSTATUS = false">关闭</span>
          <!-- btnStatus -->
        </div>
      </div>
    </Modal>
    <!-- 新增评价表 -->
    <Modal v-model="newEvaluate" :mask-closable="false" title="新增评价表" width="650" :footer-hide="true" class="modal">
      <div class="body">
        <div class="bodyFrom">
          <div class="fromItem">
            <div class="labelTitle">
              <span class="bitian">*</span>
              <span>供应商：</span>
              <Select class="widthAuto" @on-change="supplierIdchange" filterable v-model="newfrom.supplier_id">
                <Option v-for="item in supplierSelect" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="fromItem flexbefor">
            <div class="labelTitle">
              <!-- <span>*</span> -->
              <span>供应商编号：{{ newfrom.supplier_code }}</span>
            </div>
            <div class="labelTitle margin20">
              <!-- <span>*</span> -->
              <span>联系电话：</span>
              <Input :readonly="statusTable == '0'" @on-change="onBlurds" class="widthAuto" placeholder="请输入" v-model.trim="newfrom.phone"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>供应商地址：</span>
              <Input :readonly="statusTable == '0'" type="textarea" autosize class="widthAuto" placeholder="请输入" v-model.trim="newfrom.address"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>企业类型：</span>
              <RadioGroup v-model="newfrom.supplier_type">
                <Radio :label="1">生产厂家</Radio>
                <Radio :label="2">经销商</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>产品类型：</span>
              <CheckboxGroup v-model="newfrom.product_type" @on-change="changeshs">
                <Checkbox class="width120" label="1">高耗</Checkbox>
                <Checkbox class="width120" label="2">普耗</Checkbox>
                <Checkbox class="width120" label="3">手术器械</Checkbox>
                <Checkbox class="width120" label="4">设备</Checkbox>
              </CheckboxGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">有效的营业执照：</span>
              <RadioGroup v-model="newfrom.businesslicense_is_effective">
                <Radio class="width80" :label="1">有</Radio>
                <Radio class="width80" :label="2">无</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">有效的许可证/备案凭证：</span>
              <RadioGroup v-model="newfrom.allowlicense_is_effective">
                <Radio class="width80" :label="1">有</Radio>
                <Radio class="width80" :label="2">无</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">质量管理体系运营情况：</span>
              <RadioGroup v-model="newfrom.is_quality_management">
                <Radio class="width80" :label="1">有</Radio>
                <Radio class="width80" :label="2">无</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">产品评价：</span>
              <RadioGroup v-model="newfrom.product_evaluation">
                <Radio class="width80" :label="1">合格</Radio>
                <Radio class="width80" :label="2">不合格</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>说明：</span>
              <Input :readonly="statusTable == '0'" :maxlength="100" class="widthAuto" placeholder="请输入" v-model.trim="newfrom.product_evaluation_explain"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">质量稳定性：</span>
              <RadioGroup v-model="newfrom.mass_stability">
                <Radio class="width80" :label="1">好</Radio>
                <Radio class="width80" :label="2">不好</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>说明：</span>
              <Input :readonly="statusTable == '0'" :maxlength="100" class="widthAuto" placeholder="请输入" v-model.trim="newfrom.mass_stability_explain"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">交付及时性、准确性：</span>
              <RadioGroup v-model="newfrom.delivery_evaluation">
                <Radio class="width80" :label="1">好</Radio>
                <Radio class="width80" :label="2">不好</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>说明：</span>
              <Input :readonly="statusTable == '0'" :maxlength="100" class="widthAuto" placeholder="请输入" v-model.trim="newfrom.delivery_evaluation_explain"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span class="Width185">售后服务情况：</span>
              <RadioGroup v-model="newfrom.aftersales_evaluation">
                <Radio class="width80" :label="1">好</Radio>
                <Radio class="width80" :label="2">不好</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle">
              <span>说明：</span>
              <Input :readonly="statusTable == '0'" :maxlength="100" class="widthAuto" placeholder="请输入" v-model.trim="newfrom.aftersales_evaluation_explain"></Input>
            </div>
          </div>
          <div class="fromItem">
            <div class="labelTitle cleaFlex">
              <span class="width80">结论：</span>
              <RadioGroup v-model="newfrom.conclusion">
                <Radio :label="1">合格供应商</Radio>
                <Radio :label="2">建议列入合格供应商名录</Radio>
                <br />
                <Radio :label="3">不合格供应商</Radio>
                <Radio :label="4">建议列入不合格供应商名单</Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="fromItem" v-if="statusTable == '1'">
            <div class="labelTitle">
              <span>评价人：{{ username }}</span>
            </div>
          </div>
          <div class="fromItem" v-if="statusTable != '1'">
            <div class="labelTitle">
              <span class="Width80">评价人：</span>
              <Select class="Width150" clearable filterable v-model="toExamine.evaluation_user">
                <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
              </Select>
            </div>
            <div class="labelTitle">
              <span class="Width80">审核人：</span>
              <Select class="Width150" clearable filterable v-model="toExamine.audit_user">
                <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
              </Select>
            </div>
            <div class="labelTitle">
              <span class="Width80">批准人：</span>
              <Select class="Width150" clearable filterable v-model="toExamine.approval_user">
                <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
              </Select>
            </div>
          </div>
        </div>
        <div class="foot">
          <span class="pageBtn finger btnCancle" v-if="statusTable == '1'" @click="newEvaluate = false">取消</span>
          <span class="pageBtn finger marginLeft20 btnSure" v-if="statusTable == '1'" @click="commitNewpl">提交</span>
          <span class="pageBtn finger marginLeft20 btnSure" v-if="statusTable == '0' || statusTable == '3'" @click="newEvaluate = false">关闭</span>
          <span class="pageBtn finger marginLeft20 btnSure padd" v-if="statusTable == '2'" @click="toExamineInfo(1)">审核通过</span>
          <span class="pageBtn finger marginLeft20 btnSure padd" v-if="statusTable == '2'" @click="toExamineInfo(2)">审核驳回</span>
          <span class="pageBtn finger marginLeft20 btnSure" v-if="statusTable == '3'" @click="cancalEadit(false)">确定</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'factoryManage',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    },
  },
  data() {
    return {
      toExamine: {
        evaluation_user: '',
        audit_user: '',
        approval_user: '',
      },
      username: '',
      statusTable: '0',
      userList: [],
      reasonSTATUS: false,
      btnStatus: '',
      newfrom: {
        supplier_name: '',
        supplier_code: null,
        supplier_id: null,
        phone: null,
        address: null,
        supplier_type: null,
        product_type: [],
        businesslicense_is_effective: null,
        allowlicense_is_effective: null,
        is_quality_management: null,
        product_evaluation: null,
        product_evaluation_explain: null,
        mass_stability: null,
        mass_stability_explain: null,
        delivery_evaluation: null,
        delivery_evaluation_explain: null,
        aftersales_evaluation: null,
        aftersales_evaluation_explain: null,
        conclusion: null,
      },
      newEvaluate: false,
      key: null,
      order: null,
      isLoad: true,
      navFrist: [
        {
          name: '全部',
          num: 0,
          type: 1,
        },
        {
          name: '待审核',
          num: 0,
          type: 2,
        },
        {
          name: '已审核',
          num: 0,
          type: 3,
        },
        {
          name: '审核驳回',
          num: 0,
          type: 4,
        },
      ],
      clickIndex: 1,
      listColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '供应商编号',
          key: 'supplier_code',
          align: 'center',
          sortable: 'custom',
          // width: 150,
          minWidth: 100,
        },
        {
          title: '供应商名称',
          align: 'center',
          minWidth: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.supplier_name
              ),
            ])
          },
        },
        {
          title: '企业类型',
          key: 'supplier_type',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = param.row.supplier_type == 1 ? '生产厂家' : '经销商'
            return h('div', [h('div', {}, str)])
          },
        },
        {
          title: '评价时间',
          key: 'insert_time',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '评价人',
          key: 'evaluation_user',
          align: 'center',
          minWidth: 100,
          //  width: 200,
        },
        {
          title: '审批时间',
          key: 'approval_time',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '审核人',
          key: 'audit_user',
          align: 'center',
          minWidth: 100,
          //  width: 200,
        },
        {
          title: '状态',
          align: 'center',
          key: 'approval_status',
          minWidth: 80,
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 200,
          render: (h, param) => {
            let status = param.row.approval_status
            switch (status) {
              case '待审核':
                return h('div', { class: this.$data.modifyAble ? 'disFlex' : '' }, [
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '0'
                        },
                      },
                    },
                    '查看'
                  ),
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '3'
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                        content_left: true,
                        fl: true,
                      },
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.newEvaluate = true
                          this.statusTable = '2'
                          this.row = param.row
                          this.lookInfo(param.row)
                        },
                      },
                    },
                    '审核'
                  ),
                ])
              case '审核通过':
                return h('div', { class: this.$data.modifyAble ? 'disFlex' : '' }, [
                  h(
                    'div',
                    {
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '0'
                        },
                      },
                    },
                    '查看'
                  ),
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '3'
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                        content_left: true,
                        fl: true,
                      },
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.ExportTable(param.row)
                        },
                      },
                    },
                    '导出评价表'
                  ),
                ])
              case '审核驳回':
                return h('div', { class: this.$data.modifyAble ? 'disFlex' : '' }, [
                  h(
                    'div',
                    {
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '0'
                        },
                      },
                    },
                    '查看'
                  ),
                  h(
                    'div',
                    {
                      // class: 'tableFont',
                      class: 'tableFont content_left fl',
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          this.lookInfo(param.row)
                          this.statusTable = '3'
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                        content_left: true,
                        fl: true,
                      },
                      style: {
                        marginLeft: '20px',
                      },
                      on: {
                        click: () => {
                          // this.lookInfo(param.row)
                          this.btnStatus = 1
                          this.row = param.row
                          this.reasonSTATUS = true
                          this.supplierEvaluationDismiss()
                        },
                      },
                    },
                    '驳回原因'
                  ),
                ])
            }
          },
        },
      ],
      listData: [],
      supplierSelect: [],
      statusSelect: [{ status_str: '待审核' }, { status_str: '已审核' }, { status_str: '审核驳回' }],
      searchForm: {
        supplier_id: null,
        evaluation_start_time: null,
        evaluation_end_time: null,
        approval_start_time: null,
        approval_end_time: null,
        audit_state: null,
      },
      options: {}, // 时间范围设置
      pageSearch: {}, // 查询条件
      total: 1,
      pages: {
        page: 1,
        rows: 10,
      },
      examine: '',
      /* 产品信息表头 */
      productData: [] /* 产品信息数据 */,
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      dismiss_reason: '',
      row: {},
    }
  },
  methods: {
    // 数组
    changeshs(e) {
      console.log(this.newfrom.product_type, '数组', e)
    },
    // 编辑
    cancalEadit(show, num) {
      console.log(this.newfrom.product_type, '数组')
      this.newfrom.product_type = this.newfrom.product_type ? this.newfrom.product_type.join(',') : ''
      let obj = Object.assign({}, this.newfrom, this.toExamine)
      this.$http.put(this.$api.supplierEvaluationManage, obj, true).then(res => {
        if (show) {
          let obj2 = {
            id: this.row.id,
            audit_state: '',
            dismiss_reason: '',
            evaluation_user: this.toExamine.evaluation_user,
            audit_user: this.toExamine.audit_user,
            approval_user: this.toExamine.approval_user,
          }
          if (num == 1) {
            obj2.audit_state = 1
            obj2.dismiss_reason = null
            this.$http.post(this.$api.supplierEvaluationAudit, obj2, true).then(res => {
              this.$Message.success('审核成功')
              this.newEvaluate = false
              this.getTableData(this.pages, this.examine, {})
            })
          } else {
            obj2.audit_state = 2
            obj2.dismiss_reason = this.dismiss_reason
            this.$http.post(this.$api.supplierEvaluationAudit, obj2, true).then(res => {
              this.$Message.success('审核驳回成功')
              this.reasonSTATUS = false
              this.newEvaluate = false
              this.getTableData(this.pages, this.examine, {})
            })
          }
        } else {
          this.$Message.success('修改成功')
        }

        this.newEvaluate = false
        this.getTableData(this.pages, this.examine, {})
      })
    },
    // 手机号验证
    onBlurds(e) {
      if (!e) return
      this.$nextTick(() => {
        this.newfrom.phone = this.$utils.formatPhone(e.target.value) ? this.$utils.formatPhone(e.target.value) : ''
      })
    },
    // 导出评价表
    ExportTable(row) {
      this.$http.downFile(this.$api.supplierEvaluationExport, { id: row.id }, true).then(res => {
        console.log(res)
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = `${row.supplier_name}评价表.docx`
        aLink.click()
      })
    },
    // 新增
    newAdded() {
      this.newEvaluate = true
      this.newfrom = {}
      this.toExamine = {}
      this.statusTable = '1'
    },

    // 驳回原因
    supplierEvaluationDismiss() {
      this.$http.get(this.$api.supplierEvaluationDismiss, { id: this.row.id }).then(res => {
        this.dismiss_reason = res.data
      })
    },
    // 获取评价人id
    getPjrId(name) {
      let id = ''
      this.userList.forEach(item => {
        if (item.user_name == name) {
          id = item.id
        }
      })
      return id
    },
    //   审核
    async toExamineInfo(num) {
      if (!this.newfrom.supplier_code || !this.newfrom.supplier_id || !this.newfrom.supplier_name) {
        this.$Message.warning('请选择供应商')
        return
      }
      let obj = {
        id: this.row.id,
        audit_state: '',
        dismiss_reason: '',
        evaluation_user: this.toExamine.evaluation_user,
        audit_user: this.toExamine.audit_user,
        approval_user: this.toExamine.approval_user,
      }
      if (num == 1) {
        await this.cancalEadit(true, num)
      } else {
        this.reasonSTATUS = true
      }
    },
    //   查看评价表
    lookInfo(row) {
      console.log(row)
      this.$http.get(this.$api.supplierEvaluationManage, { id: row.id }, true).then(res => {
        this.$nextTick(() => {
          this.newfrom = Object.assign({}, this.newfrom, res.data)
          console.log(this.newfrom.product_type)
          if (this.newfrom.product_type) {
            this.newfrom.product_type = this.newfrom.product_type.split(',')
          } else {
            this.newfrom.product_type = []
          }
          console.log(this.newfrom.product_type)
          this.toExamine.evaluation_user = res.data.evaluation_user
          this.toExamine.audit_user = res.data.audit_user
          this.toExamine.approval_user = res.data.approval_user
          this.$forceUpdate()
        })
        this.newEvaluate = true
      })
    },
    // 用户列表
    getUserList() {
      this.$http.get(this.$api.supplierEvaluationUuerList, {}).then(res => {
        this.userList = res.data
      })
    },
    // 驳回原因
    async commitreason() {
      await this.cancalEadit(true, 2)
      // let obj = {
      //     id: this.row.id,
      //     audit_state: 2,
      //     dismiss_reason: this.dismiss_reason,
      //     evaluation_user: this.toExamine.evaluation_user,
      //     audit_user: this.toExamine.audit_user,
      //     approval_user: this.toExamine.approval_user,
      // }
      // this.$http.post(this.$api.supplierEvaluationAudit, obj, true).then(res => {
      //     this.$Message.success('审核驳回成功')
      //     this.reasonSTATUS = false
      //     this.newEvaluate = false
      //     this.getTableData(this.pages, this.examine, {})
      // })
    },
    // 供应商变化
    supplierIdchange(e) {
      // console.log(e)
      if (!e) return
      this.supplierInfo(e)
    },
    // 获取供应商编码
    supplierInfo(e) {
      this.$http.get(this.$api.supplierEvaluationSupplier_info, { supplier_id: e }, false).then(res => {
        let supplierInfo = res.data
        supplierInfo.supplier_code = supplierInfo.code
        supplierInfo.address = supplierInfo.residence
        this.$nextTick(() => {
          Object.assign(this.newfrom, supplierInfo)
          this.$forceUpdate()
        })
        console.log(this.newfrom)
      })
    },
    // 新增供应商评价表
    commitNewpl() {
      if (!this.newfrom.supplier_code || !this.newfrom.supplier_id || !this.newfrom.supplier_name) {
        this.$Message.warning('请选择供应商')
        return
      }
      let obj = JSON.parse(JSON.stringify(this.newfrom))
      if (obj.product_type instanceof Array) {
        console.log(obj.product_type)
        obj.product_type = obj.product_type.join(',')
      } else {
        obj.product_type = null
      }
      this.supplierSelect.forEach(item => {
        if (item.id == this.newfrom.supplier_id) {
          this.newfrom.supplier_name = item.name
        }
      })
      this.$http.post(this.$api.supplierEvaluationManage, obj, true).then(res => {
        this.$Message.success('新增评价表成功')
        this.getTableData(this.pages, this.examine, {})
        this.newEvaluate = false
      })
    },
    // 排序
    changeSort(data) {
      this.key = data.key
      this.order = data.order
      this.getTableData(this.pages, this.examine, {})
    },
    goDetails(row) {
      this.$router.push({
        path: '/firstCampApproval',
        query: {
          supplier_id: row.id,
          is_readonly: 1,
        },
      })
    },
    addOrder() {},
    changePage(e) {
      this.pages.page = e
      this.getTableData(this.pages, this.examine, this.pageSearch)
    },
    clickNav(type) {
      // 切换tab触发的事件
      this.clickIndex = type
      if (this.clickIndex == 1) {
        this.examine = ''
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[0].num
      }
      if (this.clickIndex == 2) {
        // 待审核
        this.examine = '1'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[1].num
      }
      if (this.clickIndex == 3) {
        // 已审核
        this.examine = '2'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[2].num
      }
      if (this.clickIndex == 4) {
        // 审核驳回
        this.examine = '3'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[3].num
      }
    },

    // 获取数据
    async getTableData(pages, examine, obj) {
      let searchItem = {}
      Object.assign(searchItem, pages, { audit_state: examine }, obj)
      searchItem.sort_str = this.key
      searchItem.sort_type = this.order
      this.isLoad = true
      let res = await this.$http.get(this.$api.supplierEvaluationList, searchItem, true)
      this.isLoad = false
      // 表格数据
      this.listData = res.data.result
      console.log(res)
      // 日期处理
      this.listData.forEach((item, index) => {
        item.approval_time = item.approval_time ? (item.approval_time == '长期' ? '长期' : this.$moment(item.approval_time * 1000).format('YYYY-MM-DD')) : ''
        item.insert_time = item.insert_time ? (item.insert_time == '长期' ? '长期' : this.$moment(item.insert_time * 1000).format('YYYY-MM-DD')) : ''
        // item.regist_end = item.regist_end ? (item.regist_end == '长期' ? '长期' : this.$moment(item.regist_end * 1000).format('YYYY-MM-DD')) : ''
        // 增加序号
        item.indexs = index + 1 + (pages.page - 1) * 10
        // 处理空值到话用/代替
        for (var key in item) {
          if (!item[key]) {
            item[key] = '/'
          }
        }
      })
      // 条数
      this.total = res.data.total
      this.navFrist[0].num = res.data.total
      this.navFrist[1].num = res.data.not_audit
      this.navFrist[2].num = res.data.pass_audit
      this.navFrist[3].num = res.data.rejected_audit
    },
    // 查询
    query() {
      this.pageSearch = []
      let evaluation_start_time = this.$moment(this.searchForm.evaluation_start_time).format('YYYY-MM-DD')
      let evaluation_end_time = this.$moment(this.searchForm.evaluation_end_time).format('YYYY-MM-DD')
      let approval_start_time = this.$moment(this.searchForm.approval_start_time).format('YYYY-MM-DD')
      let approval_end_time = this.$moment(this.searchForm.approval_end_time).format('YYYY-MM-DD')
      let obj = {}
      this.pages = { page: 1, rows: 10 }
      if (this.searchForm.supplier_id) {
        this.$set(obj, 'supplier_id', this.searchForm.supplier_id)
      }
      if (evaluation_start_time !== 'Invalid date') {
        this.$set(obj, 'evaluation_start_time', evaluation_start_time)
      }
      if (evaluation_end_time !== 'Invalid date') {
        this.$set(obj, 'evaluation_end_time', evaluation_end_time)
      }
      if (approval_start_time !== 'Invalid date') {
        this.$set(obj, 'approval_start_time', approval_start_time)
      }
      if (approval_end_time !== 'Invalid date') {
        this.$set(obj, 'approval_end_time', approval_end_time)
      }
      if (this.searchForm.status) {
        let str = ''
        switch (this.searchForm.status) {
          case '待审核':
            str = '1'
            break
          case '已审核':
            str = '2'
            break
          case '审核驳回':
            str = '3'
            break
          default:
            str = ''
            break
        }
        this.$set(obj, 'audit_state', str)
      }
      this.clickIndex = 1
      this.examine = ''
      this.getTableData(this.pages, this.examine, obj)
      this.pageSearch = obj
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.searchForm.evaluation_start_time = e
        that.searchForm.evaluation_end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchForm.evaluation_start_time) - 86400000
          },
        }
      } else {
        this.searchForm.evaluation_end_time = e
      }
    },
    changeTime2(e, num) {
      if (num == 1) {
        let that = this
        that.searchForm.approval_start_time = e
        that.searchForm.approval_end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchForm.approval_start_time) - 86400000
          },
        }
      } else {
        this.searchForm.approval_end_time = e
      }
    },
    /* 获取供应商名称 */
    getSupplierList() {
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
    // 导出审批表
    output(id) {
      this.$http.downFile(this.$api.exportSupplier, { supplier_id: id }).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商审批表.xlsx'
        aLink.click()
      })
    },
  },
  async activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      await this.getTableData(this.pages, this.examine, {})
      sessionStorage.setItem('updataCache', '1')
    }
  },

  async created() {
    let flag = this.$route.query.show
    if (flag) {
      await this.clickNav(2)
    } else {
      await this.getTableData(this.pages, this.examine, {})
    }
    this.getSupplierList()
    this.getRightVisibal()
    this.getUserList()
    this.username = window.localStorage.getItem('username')
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .marginLeft20 {
  margin-left: 20px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
.scontent_left {
  text-align: center;
}
.modal {
  width: auto;
  min-width: 650px !important;
  .formMarginBtm10 {
    .ivu-form-item {
      margin-bottom: 10px !important;
    }
  }
  .label {
    width: 200px;
  }
  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding-right: 8px;
    }
  }

  .foot {
    text-align: right;
    margin-top: 20px;
  }
  .bodyFrom {
    width: 100%;
    .fromItem {
      display: flex;
      align-items: center;
      margin: 15px 0px;
      // justify-content: space-between;
      .labelTitle {
        display: flex;
        // flex: 1;
        width: 100%;
        span {
          // word-wrap: normal;
          vertical-align: middle;
          display: flex;
          align-items: center;
          font-size: 15px;
          white-space: nowrap;
          color: #000000;
          // font-weight: 550;
        }
      }
      .cleaFlex {
        span {
          vertical-align: top;
          display: inline-block;
        }
      }
    }
  }
}
.widthAuto {
  width: 100% !important;
  // max-width: 550px !important;
}
.bitian {
  color: red !important;
}
.margin20 {
  margin-left: 20px;
}
.marginLeft {
  margin-left: 100px;
}
.Width185 {
  width: 200px;
}
.width120 {
  width: 120px;
  min-width: 80px;
}
.Width150 {
  max-width: 150px;
}
.Width300 {
  max-width: 270px;
}
.width80 {
  width: 80px !important;
  min-width: 50px !important;
}
/deep/ .ivu-radio-wrapper {
  max-width: 280px;
  min-width: 152px;
  color: #000000 !important;
  font-size: 15px !important;

  // font-weight: 550;
}
/deep/ .ivu-checkbox-group-item {
  max-width: 200px;
  min-width: 80px;
  color: #000000 !important;
  font-size: 15px !important;
  // font-weight: 550;
}
/deep/ .ivu-select-disabled .ivu-select-selection {
  background-color: white !important;
}
/deep/ .ivu-checkbox-group {
  display: flex !important;
}
.fl {
  float: left;
}
.padd {
  width: auto;
  padding: 0px 10px;
  box-sizing: border-box;
}
.flexbefor {
  display: flex;
  // justify-content: ;
}
.disFlex {
  display: flex;
  justify-content: space-around;
}
.marginRight50 {
  margin-right: 50px;
}
</style>
